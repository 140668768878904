import { render, staticRenderFns } from "./EnrollmentDetailsDialogContainer.vue?vue&type=template&id=c4e71330&scoped=true&"
import script from "./EnrollmentDetailsDialogContainer.vue?vue&type=script&lang=ts&"
export * from "./EnrollmentDetailsDialogContainer.vue?vue&type=script&lang=ts&"
import style0 from "./EnrollmentDetailsDialogContainer.vue?vue&type=style&index=0&id=c4e71330&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4e71330",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VAlert,VBtn,VCard,VCardActions,VCardText,VDivider,VForm})

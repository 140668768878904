










import sink from '@/sink';
import { defineComponent, onUnmounted } from '@vue/composition-api';
import { ofType } from 'ts-action-operators';
import {
  EnrollmentSubmitted,
  PatientEnrollmentSuccessful,
} from '../+state/events';
import EnrollmentDetailsDialogContainer from './EnrollmentDetailsDialogContainer.vue';

export default defineComponent({
  components: {
    EnrollmentDetailsDialogContainer,
  },
  setup(props, context) {
    const navToPatientDetailEffect$ = sink.events$
      .pipe(ofType(PatientEnrollmentSuccessful))
      .subscribe(event => {
        if (context.root.$route.name !== 'patientsDetailSummary') {
          context.root.$router.push(`/patients/${event.payload.patientId}`);
        }
      });

    onUnmounted(() => {
      navToPatientDetailEffect$.unsubscribe();
    });

    return {
      enroll: payload => sink.broadcast(EnrollmentSubmitted(payload)),
    };
  },
});

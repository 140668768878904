





























import sink from '@/sink';
import { InlineLoader, Alert } from '@/shared';
import { defineComponent } from '@vue/composition-api';
import {
  EnrollmentChangeStatusCancelClicked,
  UnenrollRequested,
} from '../+state/events';
import DialogTitle from '@/dialog/DialogTitle.vue';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import { EnrollmentCreateDialog } from '.';

export default defineComponent({
  components: {
    Alert,
    InlineLoader,
    DialogTitle,
  },
  setup() {
    const selectedEnrollment = sink.select(
      'patients.detail.summary.selected-enrollment',
    ).value;
    const enrolled = selectedEnrollment.approval_status === 'approved';
    const authoringId = selectedEnrollment.survey.authoring_id;
    const loading = sink.select('patients.detail.summary.enrollments.loading');

    return {
      enrolled,
      dialogText: enrolled
        ? {
            title: 'Unenroll?',
            description:
              'Are you sure you want to unenroll the patient from this program? Unenrolling the patient will stop all program communications. In the future you will be able to reenroll the patient.',
            button: 'Unenroll Patient',
            loading: 'Unenrolling Patient...',
            errorMessage:
              'Something went wrong. Please try again, or try contacting your Customer Success representative.',
          }
        : {
            title: 'Re-enroll?',
            description:
              'The patient was previously unenrolled from this program. Would you like to re-enroll this patient? Chats will resume based on configurations for the program.',
            button: 'Re-enroll Patient',
            loading: 'Re-enrolling Patient...',
            errorMessage:
              'Something went wrong. Please try again, or try contacting your Customer Success representative.',
          },
      error: sink.select('patients.detail.summary.enrollments.error'),
      cancel: () => sink.broadcast(EnrollmentChangeStatusCancelClicked()),
      loading,
      updateStatus: () => {
        if (enrolled) {
          sink.broadcast(UnenrollRequested({ authoringId }));
          return;
        }

        DialogInstance.close();
        DialogInstance.open(EnrollmentCreateDialog);
      },
    };
  },
});

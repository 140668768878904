































































import { PreferredCommunicationEventPayload } from '@conversa/bedazzled/src/inputs/PreferredCommunication/preferred-communication-event.interface';
import {
  computed,
  defineComponent,
  ref,
  watchEffect,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api';
import {
  TelInputValidEvent,
  TelInputCountryChangeEvent,
} from '../models/preferred-communication-event.interface';
import { VueTelInput } from 'vue-tel-input';

export default defineComponent({
  components: { VueTelInput },
  props: ['phoneText', 'emailText', 'preference', 'smsEnabled'],
  emits: ['commValues'],
  setup(props, { emit }) {
    const phone = ref(null);
    const email = ref(null);
    const phoneData = ref({
      valid: false,
      countryCode: null,
      number: null,
      countryCallingCode: null,
    });

    const notificationType = ref(props.preference);

    const isPhone = computed(() => notificationType.value !== 'email');

    const isEmail = computed(() => notificationType.value === 'email');

    const phoneRequired = computed(
      () =>
        !!props.phoneText?.length ||
        (!props.phoneText?.length && !props.emailText?.length),
    );

    const preferred = computed(() => {
      if (phone.value?.length && email.value?.length) {
        return notificationType.value || 'sms';
      }

      switch (true) {
        case !!phone.value?.length:
          return 'sms';

        case !!email.value?.length:
          return 'email';

        default:
          return null;
      }
    });

    const watchStopHandler = watchEffect(() => {
      // We do not want to emit empty strings
      // As those have a different meaning for both
      // HTML required attributes and our API
      const payload: PreferredCommunicationEventPayload = {
        phone:
          phone.value === ''
            ? null
            : `+${phoneData.value.countryCallingCode}${phoneData.value.number}|country|=${phoneData.value.countryCode}`,
        email: email.value || null,
        preferred: preferred.value,
      };
      emit('commValues', payload);
    });

    const onCountryChange = (event: TelInputCountryChangeEvent) => {
      phoneData.value.countryCallingCode = event.dialCode;
      phoneData.value.countryCode = event.iso2;
    };

    onMounted(() => {
      phone.value = props.phoneText;
      email.value = props.emailText;
    });

    onBeforeUnmount(() => {
      watchStopHandler();
    });

    const rules = {
      required: value => !!value || 'This field is required',
    };

    const validationProps = {
      true: {
        required: props.emailText && !!props.emailText.length,
      },
      false: {
        rules: [rules.required],
      },
    }[props.smsEnabled];

    const onPhoneCorrectnessChanged = (event: TelInputValidEvent) => {
      if (event.number) {
        phoneData.value = {
          ...event,
          number: event.nationalNumber,
        };
      }
    };

    return {
      validationProps,
      phone,
      email,
      phoneRequired,
      notificationType,
      isPhone,
      isEmail,
      phoneData,
      onPhoneCorrectnessChanged,
      onCountryChange,
    };
  },
});












import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import { EnrollmentUpdateSubmitted } from '../+state/events';
import EnrollmentDetailsDialogContainer from './EnrollmentDetailsDialogContainer.vue';

export default defineComponent({
  components: {
    EnrollmentDetailsDialogContainer,
  },
  setup() {
    return {
      enroll: payload => sink.broadcast(EnrollmentUpdateSubmitted(payload)),
    };
  },
});

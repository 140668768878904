










































import sink from '@/sink';
import { defineComponent } from '@vue/composition-api';
import DialogTitle from '@/dialog/DialogTitle.vue';
import { InlineLoader } from '@/shared';
import { ExpandableCard } from '@conversa/bedazzled/src/cards';

import { SurveySelected } from '../+state/events';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import { EnrollmentCreateDialog } from '.';

export default defineComponent({
  components: {
    DialogTitle,
    InlineLoader,
    ExpandableCard,
  },
  setup() {
    return {
      surveys: sink.select('patients.detail.summary.surveys'),
      loading: sink.select('patients.detail.summary.enrollments.loading'),
      cancelDialog: () => DialogInstance.close(),
      selectSurvey: surveyId => {
        DialogInstance.close();
        sink.broadcast(SurveySelected({ id: surveyId }));
        DialogInstance.open(EnrollmentCreateDialog);
      },
    };
  },
});

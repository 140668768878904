









































































































import {
  defineComponent,
  UnwrapRef,
  reactive,
  onUnmounted,
  ref,
} from '@vue/composition-api';
import Vue, { VueConstructor } from 'vue';
import sink from '@/sink';
import {
  EnrollmentCreateDialogBackClicked,
  EnrollmentDetailsDialogCancelClicked,
  EnrollmentDetailsUnmounted,
} from '../+state/events';
import DialogTitle from '@/dialog/DialogTitle.vue';
import { SurveyConceptType } from '@/shared';
import { dateFormatter } from '../../../../../../../../shared/date-formatter';
import DatePicker from '@/shared/components/DatePicker.vue';
import { formRules } from '@/shared/form-rules';
import { InlineLoader } from '@/shared';
import { VSelect, VTextField } from 'vuetify/lib';
import { DialogInstance } from '@conversa/bedazzled/src/dialog';
import EnrollmentProgramListDialog from './EnrollmentProgramListDialog.vue';
import { VueTelInput } from 'vue-tel-input';
import {
  TelInputValidEvent,
  TelInputCountryChangeEvent,
} from '@/shared/models/preferred-communication-event.interface';

interface LocalConcept {
  id: number;
  component: VueConstructor<Vue> | string;
  required: boolean;
  content?: string;
  value?: string | number | number[];
  options?: { value: number; content: string }[];
  text?: string;
  type?: SurveyConceptType;
  placeholder?: string;
  legend?: string;
  multiple?: boolean;
}

const inputMap = {
  mult: 'v-select',
  checks: 'v-select',
  email: 'v-text-field',
  float: 'v-text-field',
  int: 'v-text-field',
  result: 'v-text-field',
  phone: 'v-text-field',
  text: 'v-text-field',
  date: DatePicker,
};

export default defineComponent({
  props: ['title', 'successButtonCopy', 'showBackButton', 'loadingCopy'],
  emits: ['enroll'],
  components: {
    DialogTitle,
    VSelect,
    VTextField,
    InlineLoader,
    VueTelInput,
  },
  setup(_, context) {
    const error = sink.select('patients.detail.summary.enrollments.error');
    const program = sink.select('patients.detail.summary.selected-survey')
      .value;
    const loading = sink.select('patients.detail.summary.enrollments.loading');
    const phoneInputModel = ref(null);
    const phoneData = ref({
      valid: true,
      countryCode: null,
      number: null,
      countryCallingCode: null,
    });
    let firstLoad = true;

    onUnmounted(sink.lazyBroadcast(EnrollmentDetailsUnmounted()));

    const concepts: UnwrapRef<LocalConcept>[] = program.concepts.map(
      concept => {
        const conceptRules = [];

        switch (concept.type) {
          case 'email':
            conceptRules.push(formRules.emailVal);
            break;
          default:
            break;
        }
        if (concept.required) {
          conceptRules.push(formRules.required);
        }

        switch (concept.type) {
          case 'mult':
          case 'checks': {
            return reactive({
              id: concept.id,
              type: concept.type,
              text: concept.text,
              required: concept.required,
              clearable: !concept.required,
              rules: conceptRules,
              component: inputMap[concept.type] || 'v-text-field',
              value: concept.value,
              placeholder: 'Please Select',
              multiple: concept.type === 'checks',
              items: concept.options.map(option => ({
                value: option.id,
                text: option.content,
              })),
            });
          }

          default: {
            return reactive({
              id: concept.id,
              type:
                (['float', 'int'].includes(concept.type) && 'number') ||
                concept.type,
              text: concept.text,
              required: concept.required,
              rules: conceptRules,
              component: inputMap[concept.type] || 'v-text-field',
              value: concept.value,
              content: concept.text,
              step: concept.type === 'float' && 'any',
            });
          }
        }
      },
    );

    const onInputChanged = (event: { id: number; value: string }) => {
      const concept = concepts.find(c => c.id === event.id);
      concept.value = event.value; // this is utilized by inputs like text
    };

    const phoneConcept = concepts.find(c => c.type === 'phone');

    if (phoneConcept) {
      phoneInputModel.value = phoneConcept.value;
      phoneData.value.valid = !phoneConcept.required;
    }

    const onPhoneCorrectnessChanged = (event: TelInputValidEvent) => {
      if (event.number && event.valid) {
        phoneData.value = event;
      } else if (firstLoad || !phoneConcept.required) {
        firstLoad = false;
        phoneData.value.valid = true;
      } else {
        phoneData.value.valid = false;
      }
    };

    const onCountryChange = (event: TelInputCountryChangeEvent) => {
      phoneData.value.countryCode = event.iso2;
    };

    const onPhoneInputChange = (phoneNumber: string) => {
      if (phoneNumber.length === 1) {
        phoneData.value.valid = false;
      }
    };

    const enroll = () => {
      context.emit('enroll', {
        authoringId: program.authoring_id,
        concepts: concepts
          .filter(concept => concept.value !== null) // empty strings, 0, negative Numbers.. all valid responses
          .map(concept => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let value: any = concept.value;

            // 'date' fields must be sent in en-US MM/DD/YYYY format
            if (concept.type === 'date') {
              value = dateFormatter(
                concept.value as string,
                'MM/DD/YYYY HH:MM',
                'date',
              );
            }

            // 'checks' is special, as of 6/18/21 existing backend code still requires this during a validation step
            if (concept.type === 'checks' && Array.isArray(concept.value)) {
              value = concept.value.reduce((acc, conceptId) => {
                acc[conceptId] = 'on';
                return acc;
              }, {});
            }

            if (concept.type === 'phone') {
              value = `${phoneData.value.number}`;
            }

            return {
              id: concept.id,
              value,
            };
          }),
      });
    };

    const isFormValid = ref(false);

    const onIsFormValid = val => (isFormValid.value = val);

    return {
      isFormValid,
      onIsFormValid,
      programName: program.name,
      concepts,
      error,
      loading,
      phoneInputModel,
      phoneData,
      onInputChanged,
      onPhoneInputChange,
      onCountryChange,
      onPhoneCorrectnessChanged,
      enroll,
      back: () => {
        sink.broadcast(EnrollmentCreateDialogBackClicked());
        DialogInstance.open(EnrollmentProgramListDialog);
      },
      cancel: () => {
        sink.broadcast(EnrollmentDetailsDialogCancelClicked());
      },
      onClear: event => context.emit('clear', event),
    };
  },
});
